const closeModal  = () => {

    const sage = {close_id: "sageCrossClose", div_id: "modal-expo-sage"};
    addModalEvent(sage);
    const creche = {close_id: "crecheCrossClose", div_id: "modal-expo-creche"};
    addModalEvent(creche);
    const loup = {close_id: "loupCrossClose", div_id: "modal-expo-loup"};
    addModalEvent(loup);
    const fumeur = {close_id: "fumeurCrossClose", div_id: "modal-expo-fumeur"};
    addModalEvent(fumeur);
    const damenature = {close_id: "damenatureCrossClose", div_id: "modal-expo-damenature"};
    addModalEvent(damenature);

    function addModalEvent(modal) {
        const crossCloseEl = document.getElementById(modal.close_id);
        if (crossCloseEl) {
            crossCloseEl.addEventListener('click', (event) => {closeModalFunction(modal.div_id);});    
        }
    };

    function closeModalFunction(div_id) {
        const modal = document.getElementById(div_id);
        modal.style.display = 'none';
    }    
}

export default closeModal;